"use strict";

var _interopRequireDefault = require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.get = get;
exports.getDictMap = getDictMap;
var _request = _interopRequireDefault(require("@/utils/request"));
function get(dictName) {
  var params = {
    dictName: dictName,
    page: 1,
    size: 9999
  };
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'get',
    params: params
  });
}
function getDictMap(dictName) {
  var params = {
    dictName: dictName,
    page: 1,
    size: 9999
  };
  return (0, _request.default)({
    url: 'api/dictDetail/map',
    method: 'get',
    params: params
  });
}
function add(data) {
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'post',
    data: data
  });
}
function del(id) {
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'delete',
    data: data
  });
}
function edit(data) {
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'put',
    data: data
  });
}
var _default = exports.default = {
  add: add,
  edit: edit,
  del: del
};