"use strict";

var _interopRequireDefault = require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));
var _SizeSelect = _interopRequireDefault(require("@/components/SizeSelect"));
var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // import Doc from '@/components/Doc'
// import Search from '@/components/HeaderSearch'
var _default = exports.default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default,
    SizeSelect: _SizeSelect.default
    // Search,
    // Doc
  },
  data: function data() {
    return {
      Avatar: _avatar.default,
      dialogVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'device', 'user', 'baseApi'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open: function open() {
      var _this = this;
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.logout();
      });
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
      });
    }
  }
};