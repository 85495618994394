"use strict";

var _interopRequireDefault = require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _axios = _interopRequireDefault(require("axios"));
var _routers = _interopRequireDefault(require("@/router/routers"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _settings = _interopRequireDefault(require("@/settings"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var baseURL = '';
switch (process.env.NODE_ENV) {
  case 'production':
  case 'development':
    baseURL = process.env.VUE_APP_BASE_API;
    break;
  default:
    baseURL = '/';
}

// 创建axios实例
var service = _axios.default.create({
  baseURL: baseURL,
  timeout: _settings.default.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['Content-Type'] = 'application/json';
  // console.log(config, 'request.js config')
  return config;
}, function (error) {
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  // console.log(response, response.headers['content-type'])
  if (response.headers['content-type'] && (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || response.headers['content-type'] === 'text/csv')) {
    // 从响应头中获取文件名和文件类型
    var contentDisposition = response.headers['content-disposition'];
    console.log(contentDisposition, 'contentDisposition');
    var filename = contentDisposition.split('filename*=UTF-8\'\'')[1] || contentDisposition.split('filename=')[1];
    if (!filename) {
      // 错误处理代码
      console.error('文件名解析失败');
      return;
    }
    // 解码文件名
    filename = decodeURIComponent(filename);
    var contentType = response.headers['content-type'];
    if (!contentType) {
      // 错误处理代码
      console.error('响应头中缺少 content-type');
      return;
    }
    // 构造一个Blob对象来处理数据，并设置文件类型
    var blob = new Blob([response.data], {
      type: contentType + ';charset=utf-8'
    });

    // 兼容IE10
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      // 创建一个下载链接
      var link = document.createElement('a');
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.download = decodeURI(filename);

      // 添加到DOM树中并触发下载
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  // 非文件下载的响应，直接返回响应数据
  return response.data;
}, function (error) {
  // 兼容blob下载出错json提示
  if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
    var reader = new FileReader();
    reader.readAsText(error.response.data, 'utf-8');
    reader.onload = function (e) {
      var errorMsg = JSON.parse(reader.result).msg;
      _elementUi.Notification.error({
        title: errorMsg,
        duration: 5000
      });
    };
  } else {
    // let code = 0
    var http_status = 200;
    // console.log(error.response, 'error')
    try {
      // code = error.response.data.code
      http_status = error.response.status;
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        _elementUi.Notification.error({
          title: '网络请求超时',
          duration: 5000
        });
        return Promise.reject(error);
      }
    }
    // console.log(code, 'code')
    if (http_status) {
      if (http_status === 401) {
        // token过期
        _store.default.dispatch('LogOut').then(function () {
          // 用户登录界面提示
          _jsCookie.default.set('point', 401);
        });
        location.reload();
      } else if (http_status === 403) {
        // 禁止访问
        _routers.default.push({
          path: '/401'
        });
      } else {
        // 其他错误
        var errorMsg = error.response.data.msg;
        if (errorMsg !== undefined) {
          _elementUi.Notification.error({
            title: errorMsg,
            duration: 5000
          });
        }
      }
    } else {
      _elementUi.Notification.error({
        title: '接口请求失败',
        duration: 5000
      });
    }
  }
  return Promise.reject(error);
});
var _default = exports.default = service;