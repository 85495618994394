"use strict";

// console.log(process.env)
var env_str = process.env.VUE_APP_BASE_API;
switch (process.env.NODE_ENV) {
  case 'development':
    env_str = '开发服:' + env_str;
    break;
  case 'production':
    env_str = '正式服';
    break;
  default:
    env_str = process.env.NODE_ENV + env_str;
}
module.exports = {
  /**
   * @description 网站标题
   */
  title: '天昌物流-后台管理系统',
  /**
   * @description 是否显示 tagsView
   */
  tagsView: true,
  /**
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description token key
   */
  TokenKey: 'TOEKN',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 1200000,
  /**
   * @description 是否显示logo
   */
  sidebarLogo: false,
  /**
   * 是否显示设置的底部信息
   */
  showFooter: true,
  /**
   * 底部文字，支持html语法
   */
  // footerTxt: '© 2023 A尤 <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a>',
  footerTxt: '© 2023 天昌物流',
  /**
   * 备案号
   */
  caseNumber: '粤ICP备2023012747号-1' + '  ' + env_str
};