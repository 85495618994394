"use strict";

var _interopRequireDefault = require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logo = _interopRequireDefault(require("@/assets/images/logo.png"));
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'ELADMIN-后台管理',
      logo: _logo.default
    };
  }
};