"use strict";

var _interopRequireDefault = require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/core-js/modules/es6.array.iterator.js");
require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/core-js/modules/es6.promise.js");
require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/core-js/modules/es6.object.assign.js");
require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _Dict = _interopRequireDefault(require("./components/Dict"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/element-variables.scss");
require("./assets/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
// 数据字典

// 权限指令

// global css

// icon
// permission control

_vue.default.use(_permission.default);
_vue.default.use(_Permission.default);
_vue.default.use(_Dict.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});