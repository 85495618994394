"use strict";

var _interopRequireDefault = require("/Users/liguoxin/Desktop/web/tianchang56/tianchang56_template/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
// export function login(username, password, code, uuid) {
function login(username, password) {
  return (0, _request.default)({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password
      // code,
      // uuid
    }
  });
}
function getInfo() {
  return (0, _request.default)({
    url: 'auth/info',
    method: 'get'
  });
}
function getCodeImg() {
  return (0, _request.default)({
    url: 'auth/code',
    method: 'get'
  });
}
function logout() {
  return (0, _request.default)({
    url: 'auth/logout',
    method: 'delete'
  });
}